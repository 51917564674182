export const descriptions = [
  {
    id: 6,
    description: 'hate on scammers',
    details: 'I used the opportunity to curse scammers with * stars'
  },
  {
    id: 5,
    description: 'crush on user mov004',
    details:
      "I harrassed this user because I had a crush on him. I used to be a 10 year old girl but he thought I was a 14 year old boy. so I couldn't open up to him. I looked up his old profile on a time traveler website"
  },
  {
    id: 4,
    description: 'beef with COD hater LanceDawg',
    details:
      'he made a hate comment on youtube. he did not like COD zombies and ghosts. so I searched him on roblox and wrote him a nice message. I felt bad after.'
  },
  {
    id: 3,
    description: 'crying and emotional outbursts',
    details: 'crush on roblox moderator Shedletsky. glimpse of my crush on the user mov004'
  },
  {
    id: 2,
    description: 'messages to myself',
    details:
      'I liked to message myself from other accounts to feel wanted. I had relationships with my other accounts. die accounts swarmfire546 und XxxFunnyDrakexxX gehören mir. NOTE: gues(1116) used to be my username'
  },
  {
    id: 1,
    description: 'my first online girl friends',
    details:
      'I acted like a boy to get girls. I was painfully successful. sadly I do not have all of the chats with my gfs anymore but these were some of my babes'
  }
]
