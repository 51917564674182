import { createRoot } from 'react-dom/client'
import './styles.css'
import { App } from './App'
import one from './assets/images/1.png'
import two from './assets/images/2.png'
import three from './assets/images/3.png'
import four from './assets/images/4.png'
import five from './assets/images/5.png'
import six from './assets/images/6jpeg.png'

const pexel = (id) => `https://images.pexels.com/photos/${id}/pexels-photo-${id}.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260`
const images = [
  // Front
  { position: [0, 0, 1.5], rotation: [0, 0, 0], url: one },
  // Back
  { position: [-0.8, 0, -0.6], rotation: [0, 0, 0], url: two },
  { position: [0.8, 0, -0.6], rotation: [0, 0, 0], url: three },
  // Left
  { position: [-1.75, 0, 0.25], rotation: [0, Math.PI / 2.5, 0], url: four },
  // Right
  { position: [1.75, 0, 0.25], rotation: [0, -Math.PI / 2.5, 0], url: five },
  { position: [2.15, 0, 1.5], rotation: [0, -Math.PI / 2.5, 0], url: six }
]

createRoot(document.getElementById('root')).render(<App images={images} />)
